<template>
    <div
        id="book-meeting"
        :style="{ height: componentHeight ? `${componentHeight}px` : null }"
    >
        <v-dialog
            v-model="dialogs.bookingLoader.model"
            max-width="300"
            persistent
            content-class="booking-loader-dialog"
        >
            <v-card>
                <v-card-title>
                    <h1
                        v-if="!dialogs.bookingLoader.result"
                        class="label"
                    >
                        Buchung wird gespeichert
                    </h1>
                    <h1
                        v-else
                        class="label"
                    >
                        Buchung erfolgreich<br>gespeichert
                    </h1>
                </v-card-title>
                <v-card-text>
                    <div
                        v-if="!dialogs.bookingLoader.result"
                        id="ongoing"
                    >
                        <v-progress-linear
                            indeterminate
                        />
                        <p>
                            Wir schicken Ihnen eine Bestätigungs-Email Ihrer Buchung an Ihre angegebe E-Mail-Adresse.
                        </p>
                    </div>
                    <div
                        v-else
                        id="ready"
                    >
                        <v-icon
                            large
                        >
                            fa-thumbs-up
                        </v-icon>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        v-if="dialogs.bookingLoader.result"
                        text
                        color="text"
                        @click="closeBooking"
                    >
                        Schließen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            v-model="dialogs.booking.model"
            max-width="500"
            persistent
            scrollable
            content-class="booking-dialog"
        >
            <v-card>
                <v-card-title>
                    <h1 class="label">
                        Webinar buchen
                    </h1>
                </v-card-title>
                <v-card-text>
                    <div
                        v-if="selectedMeetingDate"
                        id="selected-meeting-date"
                    >
                        <v-alert
                            text
                            type="info"
                            color="primary"
                            outlined
                            icon="fa-video fa-sm"
                        >
                            <h1 class="label">
                                Ihr ausgewähltes Webinar:
                            </h1>
                            {{ dayjs(selectedMeetingDate.data.day).format('dddd, DD.MM.YYYY') }},<br>{{ selectedMeetingDate.data.starttime }} - {{ selectedMeetingDate.data.endtime }} Uhr
                        </v-alert>
                    </div>
                    <v-form
                        ref="bookingForm"
                        v-model="dialogs.booking.isValid"
                    >
                        <h1 class="label">
                            Zur Person
                        </h1>
                        <v-select
                            v-model="participant.person.title"
                            :items="['Herr', 'Frau']"
                            :rules="rules.required"
                            label="Anrede"
                        />
                        <v-text-field
                            v-model="participant.person.firstname"
                            label="Vorname"
                            :rules="rules.required"
                        />
                        <v-text-field
                            v-model="participant.person.lastname"
                            label="Nachname"
                            :rules="rules.required"
                        />
                        <v-text-field
                            v-model="participant.email"
                            label="E-Mail der Institution"
                            :rules="rules.required"
                            hint="An diese Adresse wird der Link zum Webinar gesendet."
                            persistent-hint
                        />
                        <v-text-field
                            v-model="participant.person.phone"
                            label="Telefonnummer"
                            :rules="rules.required"
                        />
                        <v-select
                            v-model="participant.person.position"
                            :items="['Lehrkraft/Mitarbeiter', 'Schulleitung']"
                            :rules="rules.required"
                            label="Funktion"
                        />
                        <h1 class="label">
                            Ihre Institution
                        </h1>
                        <v-text-field
                            v-model="participant.institution.name"
                            label="Name der Institution"
                            :rules="rules.required"
                        />
                        <v-select
                            v-model="participant.institution.type"
                            :items="['Kindergarten', 'Hort', 'Grundschule', 'Realschule', 'Verbundschule', 'Gymnasium', 'Gemeinschaftsschule', 'Berufliche Schule', 'Fachhochschule', 'Werkrealschule', 'Hauptschule', 'Sonderpäd. Bildungs- und Beratungszentrum'].sort().concat(['Sonstiges'])"
                            label="Art der Institution"
                            placeholder="z.B. Grundschule"
                            :rules="rules.required"
                        />
                        <v-text-field
                            v-model="participant.institution.place"
                            label="Ort der Institution"
                            :rules="rules.required"
                        />
                        <v-checkbox
                            v-model="participant.accepted"
                            label="Ich stimme der Verarbeitung meiner Daten ausschließlich zum Zweck der Durchführung eines Webinars zu."
                            :rules="rules.required"
                        />
                    </v-form>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <div id="actions">
                        <div
                            v-if="!dialogs.booking.validation"
                            id="validation-result"
                        >
                            Achtung: Es sind nicht alle benötigten Felder ausgefüllt.
                        </div>
                        <div id="button-set">
                            <v-spacer />
                            <v-btn
                                text
                                color="text"
                                @click="dialogs.booking.model = false"
                            >
                                Abbrechen
                            </v-btn>
                            <v-btn
                                outlined
                                color="primary"
                                @click="onBookMeeting"
                            >
                                <v-icon
                                    small
                                    left
                                >
                                    fa-arrow-right
                                </v-icon>
                                Buchen
                            </v-btn>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <div id="header">
            <div id="headline">
                Webinar buchen
            </div>
            <div id="description">
                Lernen Sie unser Produkt kennen. In einem kostenlosen und unverbindlichen Webinar zeigen wir Ihnen wie einfach und nützlich digitale Schulorganistation- und kommunikation sein kann.
            </div>
            <div
                id="logo"
                @click="openWebsite"
            >
                <img
                    src="https://cat4school.de/img/pages/start/hero/logo_round@2x.png"
                    alt="Cat4School Logo"
                >
                <div id="label">
                    Cat<span
                        style="color:#FFB300"
                        v-text="`4`"
                    />School
                </div>
            </div>
        </div>
        <div id="range">
            <v-btn
                icon
                :disabled="isMaxPrev"
                @click="changeRange({ action: 'prev', isMaxPrev })"
            >
                <v-icon>
                    fa-arrow-circle-left
                </v-icon>
            </v-btn>
            <div id="">
                <span class="screen-wide">
                    {{ range.start.format("dd, DD.MM.") }} - {{ range.end.format("dd, DD.MM.YYYY") }}
                </span>
                <span class="screen-small">
                    {{ range.start.format("dd, DD.MM.") }} - {{ range.end.format("dd, DD.MM.") }}
                </span>
            </div>
            <v-btn
                icon
                :disabled="isMaxNext"
                @click="changeRange({ action: 'next', isMaxNext })"
            >
                <v-icon>
                    fa-arrow-circle-right
                </v-icon>
            </v-btn>
        </div>
        <div id="list">
            <div
                v-for="(meetingDatesOfDay, day) in groupedMeetingDatesByDay"
                :key="day"
            >
                <div class="day-label">
                    <v-chip
                        outlined
                        color="grey darken-1"
                        small
                    >
                        {{ dayjs(day).format("dddd, DD.MM.YYYY") }}
                    </v-chip>
                </div>
                <meeting-date-item
                    v-for="meetingDate in meetingDatesOfDay"
                    :key="meetingDate.id"
                    :model="meetingDate"
                    @action:book="book"
                />
            </div>
            <v-alert
                v-if="!isLoading && !sortedMeetingDates.length"
                text
                outlined
                color="primary"
                type="info"
                style="margin: 2em;"
            >
                <span style="font-weight: 600">
                    Für diesen Zeitraum stehen momentan keine Webinar-Termine zur Verfügung.
                </span>
            </v-alert>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import MeetingDateItem from "./MeetingDateItem"
import dayjs from "dayjs"
import { EventBus } from "@/event-bus"
export default {
    name: "BookMeeting",
    components: {
        MeetingDateItem
    },
    data(){
        return {
            participant: {
                displayName: "",
                email: "",
                person: {
                    firstname: "",
                    lastname: "",
                    title: "",
                    phone: "",
                    position: ""
                },
                institution: {
                    name: "",
                    type: "",
                    place: ""
                },
                accepted: false
            },
            dialogs: {
                booking: {
                    model: false,
                    meetingDate: null,
                    validation: true
                },
                bookingLoader: {
                    model: false,
                    isLoading: false,
                    result: false
                }
            },
            isLoading: false,
            rules: {
                required: [
                    v => !!v || "Dieses Feld wird benötigt"
                ]
            },
            screenSize: null,
            componentHeight: null
        }
    },
    computed: {
        ...mapState("bookMeeting", ["range"]),
        ...mapGetters("bookMeeting", ["beginn", "groupedMeetingDatesByDay", "sortedMeetingDates", "isMaxPrev", "isMaxNext"]),
        mappedRange(){
            return this.$_.mapValues(this.range, dayjs => dayjs.format("YYYY-MM-DD"))
        },
        selectedMeetingDate(){
            return this.dialogs.booking.meetingDate || null
        },
        dayjs(){
            return dayjs
        }
    },
    watch: {
        range: {
            handler(){
                this.fetch()
            }
        },
        "dialogs.booking.model"(){
            const bookingForm = this.$refs.bookingForm
            if(bookingForm){ bookingForm.resetValidation() }
            this.dialogs.booking.validation = true
        },
        "dialogs.bookingLoader.isLoading"(value){
            this.dialogs.bookingLoader.model = value
        },
        participant: {
            handler(){
                this.dialogs.booking.validation = true
            },
            deep: true
        }
    },
    created(){
        this.setRange(this.beginn)
        EventBus.$on("message", (data) => {
            this.componentHeight = (data.windowInnerHeight * data.multiplicator) - 80
        })
    },
    methods: {
        ...mapActions("bookMeeting", ["fetchMeetingDates", "bookMeeting"]),
        ...mapMutations("bookMeeting", ["setRange", "changeRange"]),
        async fetch(){
            this.isLoading = true
            await this.fetchMeetingDates({ range: this.mappedRange })
            this.isLoading = false
        },
        book(model){
            this.dialogs.booking.model = true
            this.dialogs.booking.meetingDate = model
        },
        async onBookMeeting(){
            const meetingDate = this.dialogs.booking.meetingDate
            if(!meetingDate){
                return
            }
            const valid = this.$refs.bookingForm.validate()
            if(!valid){
                this.dialogs.booking.validation = false
                return
            }
            
            this.dialogs.bookingLoader.isLoading = true
            const { title, lastname } = this.participant.person
            this.participant.displayName = `${title} ${lastname}`
            const fetchingResult = await this.bookMeeting({ meetingDate, participant: this.participant })
            if(fetchingResult.type !== "success"){
                console.log(fetchingResult)
                return
            }
            this.dialogs.bookingLoader.result = true
        },
        closeBooking(){
            this.$refs.bookingForm.reset()

            this.dialogs.booking.model = false
            this.dialogs.booking.result = false
            this.dialogs.booking.validation = true

            this.dialogs.bookingLoader.model = false
            this.dialogs.bookingLoader.result = false
        },
        openWebsite(){
            window.open("https://cat4school.de", "_blank")
        }
    }
}
</script>
<style lang="scss">
    div.v-text-field{
        margin-top: 1em !important;
    }
    div.booking-dialog{
        div#selected-meeting-date{
            position: sticky;
            top: 0;
            background-color: #fff;
            z-index: 1;
            padding-bottom: 1em;
        }
        div#actions{
            flex: 1 1 0;
            > div#validation-result{
                margin: 1em;
                padding: .5em 1em;
                color: palette(Red, 500);
                background-color: palette(Red, 50);
                border-radius: .25em;
            }
            > div#button-set{
                display: flex;
            }
        }
    }
    div.booking-loader-dialog{
        div#ongoing{
            > div.v-progress-linear{
                width: 50%; margin: 2em auto;
            }
            > p{
                margin-top: 1em;
            }
        }
        div#ready{
            text-align: center; margin: 2em 0;
            > i.v-icon{
                width: 3em; height: 3em;
                border-radius: 50%;
                background-color: palette(Cyan, 100);
                color: palette(Cyan, 500);
            }
        }
    }
    
</style>
<style lang="scss" scoped>
    div#book-meeting{
        display: grid;
        grid-template-rows: auto auto 1fr;

        @include shadow(1);
        border-radius: .3em;
        position: relative;
        overflow: hidden;

        width: 800px;
        margin: 40px auto;

        .screen-small{
            display: none;
        }
        @include mediaQueryCutMax(sm){
            width: calc(100% - 2em);

            .screen-wide{
                display: none;
            }
            .screen-small{
                display: initial;
            }
        }

        > div#header{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            background-color: palette(Cyan, 500);
            padding: 1em 2em;
            padding-top: 6.8em;
            overflow: hidden;

            > div#headline{
                font-size: 2em;
                font-weight: bold;
                color: #fff;
            }
            > div#description{
                color: palette(Cyan, 50);
                font-weight: 600;
            }
            > div#logo{
                position: absolute;
                top: 0;
                right: 0;
                zoom: .6;
                
                background-color: palette(Grey, 200);
                border-radius: 50%;
                width: 10em;
                height: 10em;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                transform: translate(-3.3em, 1.5em);
                // box-shadow: inset 5px -3px 10px rgba(palette(Grey, 800), .5);
                cursor: pointer;

                @include mediaQueryCutMax(xs){
                    zoom: .4;
                }

                > img {
                    width: 3.7em;
                }
                > div#label{
                    font-weight: bold;
                    font-size: 1.3em;
                }
            }
        }
        > div#range{
            display: grid;
            grid-template-columns: auto 1fr auto;
            align-items: center;
            justify-items: center;
            font-weight: 600;
            padding: .5em 1em;
            border-bottom: 1px solid palette(Grey, 300);
            position: relative;
            z-index: 1;
            @include shadow(1);
        }
        > div#list{
            overflow-y: auto;
            padding-top: 1em;
            div.day-label{
                position: relative;
                color: palette(cyan, 500);
                padding: .5em 1em;
                // border-bottom: 1px solid palette(Grey, 200);
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background-color: palette(Grey, 300);
                    content: "";
                    transform: translateY(1.2em);
                }

                > span.v-chip{
                    border-color: palette(Grey, 200) !important;
                    background-color: palette(Grey, 100) !important;
                }
            }
            div.meeting-date-item{
                &:last-of-type{
                    border-bottom: none;
                }
            }
        }
    }
</style>