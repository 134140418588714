<template>
    <v-app
        id="app"
    >
        <div
            v-if="appMode !== 'widget'"
            id="header"
        >
            <div
                id="label"
                @click="openWebsite"
            >
                Cat<span
                    style="color:#FFB300"
                    v-text="`4`"
                />School
            </div>
            <div>
                <v-chip>
                    <v-icon
                        x-small
                        left
                        class="fa-fw"
                    >
                        fa-phone
                    </v-icon>
                    0162 - 5673212
                </v-chip>
                <v-chip @click="openWebsite">
                    <v-icon
                        x-small
                        left
                        class="fa-fw"
                    >
                        fa-globe
                    </v-icon>
                    cat4school.de
                </v-chip>
            </div>
        </div>
        <book-meeting />
        <div
            v-if="appMode !== 'widget'"
            id="footer"
        >
            <v-chip>
                <a
                    href="https://cat4school.de/kontakt"
                    target="_blank"
                >
                    Kontakt
                </a>
            </v-chip>
            <v-chip>
                <a
                    href="https://cat4school.de/impressum"
                    target="_blank"
                >
                    Impressum
                </a>
            </v-chip>
        </div>
    </v-app>
</template>
<script>
import { mapState, mapMutations } from "vuex"
import { EventBus } from "@/event-bus"
import BookMeeting from "@/components/BookMeeting"
export default {
    name: "App",
    components: {
        BookMeeting
    },
    computed: {
        ...mapState(["appMode"])
    },
    created(){
        EventBus.$on("message", (data) => {
            this.setAppMode("widget")
        })
    },
    methods: {
        ...mapMutations(["setAppMode"]),
        openWebsite(){
            window.open("https://cat4school.de", "_blank")
        }
    }
}
</script>
<style lang="scss">
    @import 'assets/css/global.scss';
    html{
        background: transparent !important;
    }
    body{
        font-family: 'proxima-nova', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        color: palette(Grey, 800);

        button:focus{
            &:before{
                opacity: 0 !important;
            }
        }
    }

    h1.label{
        font-size: 1em;
        color: palette(Cyan,500);
    }

    div.line{
        width: 100%;
        height: 1px;
        background-color: palette(Grey,300);
        margin: 0.5rem 0;
    }

    div.md-label{
        position: relative;
        font-weight: bold;
        //line-height: 1em;
        > span{
            font-weight: normal;
            display: block;
            font-size: .75em;
            //margin-top: -.5em;
            position: absolute;
            width: 100%;
            white-space: nowrap;
            bottom: -1.2em;
        }
    }

    section{
        padding: 0 1rem;
        margin: 1rem 0;
    }

    input, textarea {
        color: palette(Grey, 800) !important;
    }

    button.v-btn{ // vuetify fix vertical overflow in icon buttons (only ios? not desctop)
        i.v-icon{
            &:after{
                width: auto !important;
            }
        }
    }
</style>
<style lang="scss" scoped>
    
    div#app{
        // font-size: 16px;
        font-family: 'proxima-nova', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        // user-select: none !important;

        color: palette(Grey, 800);      
        // background-color: palette(Grey, 200);
        background: transparent;

        div#header{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .5em 1em;

            > div#label{
                font-weight: bold;
                cursor: pointer;
            }
            span.v-chip{
                background-color: transparent;
            }
            @include shadow(1);
        }

        div#footer{
            width: 800px;
            margin: 0 auto;
            padding: .5em 1em;
            display: flex;
            justify-content: flex-end;

            @include mediaQueryCutMax(xs){
                width: 100%;
            }

            span.v-chip{
                background-color: transparent;
                a{
                    color: palette(Grey, 600) !important;
                }
            }
        }

    }
</style>