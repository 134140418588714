<template>
    <div
        :class="['meeting-date-item', { booked }]"
    >
        <div id="icon">
            {{ start.format('dd').toUpperCase() }}
        </div>
        <div id="label">
            <div><strong>{{ start.format("dddd, DD.MM.YYYY") }}</strong></div>
            <div><small>{{ start.format("HH:mm") }} - {{ end.format("HH:mm") }} Uhr</small></div>
        </div>
        <v-btn
            :disabled="booked"
            outlined
            color="primary"
            min-width="119px"
            @click="$emit('action:book', model)"
        >
            <v-icon
                v-if="!booked"
                left
                small
            >
                fa-arrow-circle-right
            </v-icon>
            {{ booked ? "vergeben": "buchen" }}
        </v-btn>
    </div>
</template>

<script>
import dayjs from "dayjs"
export default {
    name: "MeetingDateItem",
    props: {
        model: Object
    },
    data(){
        return {

        }
    },
    computed: {
        booked(){
            return this.model.data.state === "booked"
        },
        start(){
            const { day, starttime } = this.model.data
            return dayjs(`${day}T${starttime}`)
        },
        end(){
            const { day, endtime } = this.model.data
            return dayjs(`${day}T${endtime}`)
        }
    },
    methods: {
        
    }
}
</script>

<style lang="scss" scoped>
    div.meeting-date-item{
        display: grid;
        grid-template-columns: auto 1fr auto;
        grid-gap: 0 1em;
        align-items: center;
        border-bottom: 1px solid palette(Grey, 200);
        padding: 1em 1em;

        &:hover{
            background-color: palette(Grey, 50);
        }

        &.booked{
            > div#icon{
                background-color: palette(Grey, 200);
                color: palette(Grey, 400);
            }
        }
        > div#icon{
            border-radius: 50%;
            width: 3em;
            height: 3em;
            background-color: palette(Cyan, 50);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            color: palette(Cyan, 500);

        }
        > div#label{
            
        }
    }
</style>