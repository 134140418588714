import Vue from "vue"
import Vuex from "vuex"

import BookMeetingModule from "./BookMeetingModule"
Vue.use(Vuex)

export default new Vuex.Store({
    //strict: process.env.NODE_ENV !== 'production',
    modules: {
        bookMeeting: BookMeetingModule
    },
    state: {
        user: null,
        schoolID: null,
        appMode: "app",
        firebaseErrorMap: {
            "auth/user-mismatch":"Die angegebenen Anmeldedaten sind nicht korrekt.",
            "auth/user-not-found":"Es ist kein Benutzer mit der angegebenen E-Mail-Adresse registriert.",
            "auth/invalid-email":"Ungültige E-Mail-Adresse.",
            "auth/email-already-in-use":"Diese E-Mail-Adresse ist bereits registriert. Melde dich mit dieser E-Mail-Adresse und deinem Passwort an.",
            "auth/weak-password":"Zu schwaches Passwort.",
            "auth/requires-recent-login":"Nochmal einloggen.",
            "auth/wrong-password":"Das eingegebene Passwort ist nicht korrekt."
        }
    },
    mutations: {
        setAppMode(state, value){
            state.appMode = value
        }
    }
})