import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors"
import "@fortawesome/fontawesome-free/css/all.css"
import "@mdi/font/css/materialdesignicons.css"
import "material-design-icons-iconfont/dist/material-design-icons.css"

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: "mdi" // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4'
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: colors.cyan.base,
                accent: colors.amber.base,
                error: colors.red.base,
                success: colors.lightGreen.base,
                text: colors.grey.darken3
            }
        }
    }
});
