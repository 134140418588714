import Vue from "vue"
import App from "./App.vue"
import store from "./store/store"
import "./registerServiceWorker"
import _ from "lodash"
import vuetify from "./plugins/vuetify"

import dayjs from "dayjs"
import "dayjs/locale/de"
dayjs.locale("de")

Object.defineProperty(Vue.prototype, "$_", { value: _ });

_.mixin({ "orderByNatural": (collection, iteratees, orders) => {
        if (collection == null) {
            return []
        }
        if (!Array.isArray(iteratees)) {
            iteratees = iteratees == null ? [] : [iteratees]
        }
        if (!Array.isArray(orders)) {
            orders = orders == null ? [] : [orders]
        }
        iteratees = iteratees.map(iterate => {
            return  _.isFunction(iterate) ? iterate : (model) => model[iterate]
        })
        const collator = new Intl.Collator("de",{numeric: true, sensitivity: "base" })
        return collection.slice().sort((a, b) => {
            const stringA = iteratees.reduce((result, iterate) => {
                const value = iterate(a)
                if(value){
                    result.push(_.trim(value.replace(/  +/g, " ")))
                }
                return result;
            },[]).join("")
            const stringB = iteratees.reduce((result, iterate) => {
                const value = iterate(b)
                if(value){
                    result.push(_.trim(value.replace(/  +/g, " ")))
                }
                return result;
            },[]).join("")
            return collator.compare(stringA, stringB)
        })
    },
    "flattenKeys": (obj, path = []) => {
        return !_.isObject(obj)
            ? { [path.join("/")]: obj }
            : _.reduce(obj, (cum, next, key) => _.merge(cum, _.flattenKeys(next, [...path, key])), {});       
    }
});


Vue.config.productionTip = false
Vue.config.devtools = false

new Vue({
    store,
    vuetify,
    render: h => h(App)
}).$mount("#app")
