import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/database"
import store from "./store/store"
import { EventBus } from "@/event-bus.js"

//Setup Firebase
const config = {
    apiKey: "AIzaSyDg_VCu3voADgBzXiE8DUQAsWoZ2oiQw6s",
    authDomain: "cat-support.firebaseapp.com",
    projectId: "cat-support",
    storageBucket: "cat-support.appspot.com",
    messagingSenderId: "107804659464",
    appId: "1:107804659464:web:85dc678325a1e6d818a6bd"
}

firebase.initializeApp(config)

const db = firebase.firestore()

export { db }