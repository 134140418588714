import firebase from "firebase/app"
import "firebase/functions"
import { db } from "@/api"
import _ from "lodash"
import dayjs from "dayjs"

export default {
    namespaced: true,
    state: {
        meetingDates: [],
        rangeSettings: {
            spacingDays: 3,
            weeks: 3,
            bookedMeetingDatesOfWeek: 1
        },
        range: null,
        screenSize: null
    },
    getters: {
        beginn(state){
            return dayjs().add(state.rangeSettings.spacingDays, "days")
        },
        isMaxPrev(state, getters){
            return state.range.start.isSame(getters.beginn, "day")
        },
        isMaxNext(state, getters){
            return state.range.end.isSame(getters.beginn.add(3, "weeks"), "day")
        },
        sortedMeetingDates(state, getters){
            console.log()
            return _.orderBy(state.meetingDates.concat(getters.bookedMeetingDatesOfRange), ["data.day", "data.starttime"])
        },
        groupedMeetingDatesByDay(state, getters){
            const grouped =  _.groupBy(getters.sortedMeetingDates, "data.day")
            return grouped
        },
        bookedMeetingDates(state, getters){
            return getters.getRandomDaysOfRange.map(d => {
                const randomStarttime = d.hour(_.random(9, 16)).minute([0, 15, 30, 45][_.random(0, 3)])
                return {
                    id: "none",
                    data: {
                        id: "none",
                        day: d.format("YYYY-MM-DD"),
                        starttime: randomStarttime.format("HH:mm"),
                        endtime: randomStarttime.add(45, "minutes").format("HH:mm"),
                        duration: 45,
                        state: "booked"
                    }
                }
            })
        },
        bookedMeetingDatesOfRange(state, getters){
            return getters.bookedMeetingDates.filter(md => {
                return md.data.day >= state.range.start.format("YYYY-MM-DD") && md.data.day <= state.range.end.format("YYYY-MM-DD")
            })
        },
        getRandomDaysOfRange(state, getters){
            const start = getters.beginn
            const end = start.add(state.rangeSettings.weeks, "weeks")
            let days = []
            let week = start
            while(week.diff(end, "week") <= -1){
                let daysOfWeek = []
                let day = week
                while(day.diff(week.add(1, "week"), "day") <= -1){
                    daysOfWeek.push(day)
                    day = day.add(1, "day")
                }
                daysOfWeek = daysOfWeek.filter(d => ![0, 6].includes(d.day()))
                daysOfWeek = _.sampleSize(daysOfWeek, state.rangeSettings.bookedMeetingDatesOfWeek)
                days = days.concat(daysOfWeek)

                week = week.add(1, "week")
            }
            return days
        }
    },
    mutations: {
        setRange(state, beginn){
            state.range = {
                start: beginn,
                end: beginn.add(1, "week")
            }
            state.screenSize = document.body.clientWidth > 600 ? "wide" : "small"
        },
        changeRange(state, { action, isMaxPrev, isMaxNext }){
            if(action === "next"){
                if(isMaxNext){
                    return
                }
                state.range = {
                    start: state.range.start.add(1, "week"),
                    end: state.range.end.add(1, "week"),
                }
            }else{
                if(isMaxPrev){
                    return
                }
                state.range = {
                    start: state.range.start.subtract(1, "week"),
                    end: state.range.end.subtract(1, "week"),
                }
            }
        }
    },
    actions: {
        async fetchMeetingDates({ state }, { range }){
            const meetingDates = await db.collection("meetingDates")
                // .where("state", "==", "free")
                .where("day", ">=", range.start)
                .where("day", "<=", range.end).orderBy("day").get()
                .then(snap => snap.docs.map(doc => ({ id: doc.id, data: doc.data(), ref: doc.ref })))
            state.meetingDates = meetingDates
            return meetingDates
        },
        async bookMeeting({ state }, { meetingDate, participant }){
            meetingDate.data.state = "booked"

            const bookMeeting = firebase.app().functions("europe-west3").httpsCallable("catsupport-meetings-bookMeeting")
            const data = {
                meetingDate: meetingDate.data, participant
            }
            const result = await bookMeeting(data).then(result => result.data)
            return result
        }
    }
}