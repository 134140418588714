import Vue from "vue";
// import Moment from 'moment';
import store from "./store/store"
import _ from "lodash"

export const EventBus = new Vue({
    data(){
        return {
            online: navigator.onLine,
            idleTimer: null,
            keyboardIsOpen: false
        }
    },
    created(){

        window.addEventListener("message", (e) => {
            if(e.data.type === "messageFromParent"){
                this.$emit("message", e.data)
            }
        })
        window.addEventListener("online", () => {
            this.online = true
            this.$emit("online", true)
        })
        window.addEventListener("offline", () => {
            this.online = false
            this.$emit("online", false)
        })

        window.addEventListener("orientationchange", (e) => {
            this.$emit("orientationchange", window.orientation)
        })

        this.$emit("windowResize", window.outerWidth)
        window.onresize = () => {
            this.$emit("windowResize", window.outerWidth)
        }

        document.body.delegateEventListener("focusin", "input, textarea", () => {
            this.$emit("keyboardShowsUp")
        })
        document.body.delegateEventListener("focusout", "input, textarea", () => {
            _.defer(() => {
                if(!document.activeElement.matches("input") && !document.activeElement.matches("textarea")){
                    this.$emit("keyboardHides")
                }
            })
        })

        if(window.visualViewport){
            window.visualViewport.addEventListener("resize", () => {
                if(window.outerHeight > window.visualViewport.height){
                    this.keyboardIsOpen = true
                    this.$emit("keyboardShowsUp")
                }else{
                    if(this.keyboardIsOpen){
                        this.$emit("keyboardHides")
                        this.keyboardIsOpen = false
                    }
                }
            })
        }
        // setInterval(() => {
        //     const today = store.getters.today()
        //     if(store.getters.dayOnLaunch !== today){
        //         this.$emit('dayChanged', today)
        //     }
        // }, 1000)
        // setInterval(() => {
        //     const time = store.getters.time()
        //     this.$emit('minuteChanged', time)
        // }, 6000)

        // document.addEventListener("mousemove", this.resetIdleTimer, false)
        // document.addEventListener("mousedown", this.resetIdleTimer, false)
        // document.addEventListener("keypress", this.resetIdleTimer, false)
        // document.addEventListener("touchmove", this.resetIdleTimer, false)
        // this.startIdleTimer()        
    },
    methods: {
        startIdleTimer(){
            this.idleTimer = setTimeout(() => {
                this.$emit("idle")
                this.resetIdleTimer()
            }, 6000)
        },
        resetIdleTimer(){
            clearTimeout(this.idleTimer)
            this.startIdleTimer()
        },

    }
})